import ElevatorImg from "./assets/elevators.png";
import "./App.css";
import { Button, Col, Row } from "antd";

function App() {
  return (
    <div>
      <header
        className="App-header"
        style={{
          backgroundImage: `url(${ElevatorImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          height: "90%",
        }}
      >
        <Row gutter={[24, 24]}>
          <Col>
          <Button
              type="primary"
              style={{
                position: "fixed",
                top: "36vh",
                left: "78vw",
                right: "10px",
                // width: 100,
                height: 50,
              }}
              onClick={() => {
                window.open("https://v1.bosch-connected-elevators.com/", "_blank");
              }}
            >
              Click here to continue to Bosch up portal
            </Button>
          </Col>
          <Col>
          <Button
              type="primary"
              style={{
                position: "fixed",
                top: "46vh",
                left: "78vw",
                right: "10px",
                // width: 100,
                height: 50,
              }}
              onClick={() => {
                window.open("https://v2.bosch-connected-elevators.com/", "_blank");
              }}
            >
              Click here to use new Bosch up portal
            </Button>
          </Col>
        </Row>
      </header>
    </div>
  );
}

export default App;
